.contenedorSellos {
	display: flex;

	justify-content: center !important;
}
.testimonios__img > h5 {
	font-size: 1rem;
}
.stamp__img {
	/* background-color: var(--color-verde-footer); */
	width: 120px;
	height: 120px;
	fill: beige;
}
.stampLink {
	text-decoration: none;
	color: inherit; /* Puedes heredar el color del texto del elemento padre si lo deseas */
}
.stampLink > img {
	background-color: rgba(240, 248, 255, 0);
	transition: ease-in-out;
}
.stampLink > img:hover {
	border: 5px solid var(--color-verde);
	background-color: var(--color-claro);
}
.stampLink:hover {
	text-decoration: none;
	color: inherit; /* Puedes heredar el color del texto del elemento padre si lo deseas */
}
@media (max-width: 700px) {
	.stamp__img {
		/* background-color: var(--color-verde-footer); */
		width: 100px;
		height: 100px;
	}
	.stampLink > h5 {
		font-size: 1rem;
		color: #565862;
	}
}
