.carousel-container {
	width: 100%;
	height: 77vh;

	/* z-index: 1; */
}

.carousel-item {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	width: 100%;
	height: 100%;
}

.carousel-item > img {
	width: 100%;
	height: 100%;

	object-fit: cover; /* Ajusta el tamaño de la imagen mientras mantiene su proporción */
}

@media (max-width: 550px) {
	.carousel-container {
		margin-top: 20vh;
		width: 100%;
		height: 100%; /* Ajusta la altura deseada para el carrusel */
		/* z-index: 1; */
	}
}
