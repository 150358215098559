header {
	width: 100%;
	height: 17vh;
	min-height: 17vh;
	position: fixed;
	z-index: 2;
}
main {
	min-height: 83vh;
	text-align: center;
	overflow-y: hidden;
}
.mainContact {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.contactoContainer {
	/* min-height: 92vh; */
	margin-top: 20vh !important;
	text-align: center;
	border-radius: 10px;
	background-color: rgba(255, 255, 255, 0.546);
	align-items: center;
}
.containerForm > p {
	font-family: Georgia;
	font-weight: bold;
}

.contactoContainer2 > h2 {
	color: var(--text-color-bar);
}
.contactoMapa > iframe {
	width: 100%;
}
.containerListaRedes li i {
	color: var(--color-salmon);
	font-size: 2rem;
}
.containerListaRedes a:hover {
	cursor: pointer !important;
}
.contactoMedios > p {
	font-family: Georgia2;
	margin: 0;
}

.editDatos {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 150px;
	height: 50px;
	justify-self: center;
	background-color: var(--color-verde);
	color: #ffffff;
	padding: 8px;
	border-radius: 10px;
	cursor: pointer;

	border: none;
}
@media (max-width: 426px) {
	.contactoMedios > p {
		font-size: 0.8rem;
	}
	.containerListaRedes li i {
		font-size: 1.5rem;
	}
}
