.divFooter {
	height: 17vh;
	min-height: 17vh;
	padding: 1vh 3vh;
	background-color: var(--color-footer);
}

.divFooter > h6 {
	margin-left: 8px;
	font-family: Georgia2;
}

.terminos {
	width: 100%;
	color: var(--text-color-claro);
	text-decoration: none;
	transition: 0.3s;
}
.terminos:hover {
	color: var(--text-color-claro);
	font-weight: bold;
}
