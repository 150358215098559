.headerError {
	width: 100%;
	height: 17vh;
}

/* main + {
	min-height: 83vh;
	text-align: center;
	overflow-y: hidden;
} */

main > h4 {
	font-size: 1rem;
}

.alInicio {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 25vw;
	height: 5vh;

	background-color: var(--color-verde);
	color: var(--color-claro);
	padding: 8px;
	border-radius: 10px;
	cursor: pointer;
	border: none;
}

.containerError {
	margin-top: 17vh;
	min-height: 83vh;
	text-align: center;
	overflow-y: hidden;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: var(--color-claro);
}
.containerError > h2 {
	margin-top: -15vh;
	font-size: 2rem;
}
@media (max-width: 700px) {
	.alInicio {
		width: 90vw;
	}
}
