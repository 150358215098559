header {
	width: 100%;
	height: 17vh;
	min-height: 17vh;
	position: fixed;
	z-index: 25;
}
main {
	min-height: 83vh;
	text-align: center;
}

.textoDescripcion {
	width: 70vw !important;
	font-size: 1rem !important;
	/* justify-content: center;
	text-align: center; */
}
/* En tu archivo Detail.Module.css */

.lead {
	display: flex;
	text-align: center;
	align-items: center;
	justify-content: center;
	font-size: 2rem;
	padding: 2vh;

	margin-bottom: -4vh;
}

.lead:hover {
	background-color: var(--color-verde);
	color: var(--color-claro);
	border-radius: 10px;
}

.aaaa {
	background-color: var(--color-oscuro);
}
.aaaa > p {
	color: var(--color-claro) !important;
	font-size: 1.5rem !important;
}
.detalle-item {
	align-items: center;
	justify-content: center;
	text-align: center;

	height: 60vh;
	background-color: var(--color-oscuro);
	width: 100% !important;
	display: flex;
}
.detalle-imagen {
	height: 100%;
	padding: 2vh;
}
.detalle-imagen > img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.detalleProducto {
	width: 100%;
}

.detalle-texto {
	width: 50%;
}
.containerDetail {
	width: 100vw !important;
	padding-top: 5vh !important;
	margin-top: 18vh !important;
	overflow-x: hidden;
	justify-content: center;
	align-items: center;
	/* padding-top: 12vh !important; */
	/* padding-bottom: 3vh !important; */
	/* min-height: 92vh; */

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 10px;
}
.containerDetail2 {
	display: flex;
	align-items: stretch;
	width: 80vw;
	margin-right: 0 !important;
	justify-content: center;
}

.detailFooter {
	width: 100%;
}

.containerImg {
	display: flex;
	flex-direction: column;
	justify-content: center;
	object-fit: contain;
	/* width: 50%; */
	max-width: 50vw;
	padding-right: 0;
}

.containerDetail3 {
	display: flex;
	background-color: rgba(255, 255, 255, 0.5);
	flex-direction: column;
	justify-content: space-between;
	/* width: 50%; */
	max-width: 25vw;
	padding: 10px 5px !important;
	border-radius: 10px;
}

@media (max-width: 1100px) {
	.containerForm {
		display: flex;
		width: 100vw;
		align-items: center !important;
		justify-content: center !important;
	}
	.containerDetail2 {
		display: flex;
		flex-direction: column;
		width: 100vw;
		align-items: center !important;
		justify-content: center !important;
		padding-left: 2vw;
	}
	.detalleProducto {
		margin-top: -15vh;
	}
	.detalle-item {
		height: 40vh;
	}
	/* .col-md-5,
	.col-md-7 {
		width: 100% !important;
	} */
	.containerDetail3 {
		max-width: 100%;
		width: 100% !important;
	}
	/* .containerDetail3 {
		width: 100% !important;
	} */
	.containerImg {
		display: flex;
		max-width: 100%;
		width: 100% !important;
		flex-direction: column-reverse;
	}
	.containerImg > img,
	.containerImg abbr button {
		width: 100%;
	}
	.carritoDetail {
		margin: 0;
		margin-bottom: 10px;
	}
	.col-md-5 > h3 {
		text-align: center;
	}
	.img-fluid {
		height: 300px;
	}
}
@media (max-width: 550px) {
}
