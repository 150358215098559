.imgEdit {
	object-fit: cover;
	margin: 3px;
}
.modalEditProduct {
	justify-content: center;
	display: flex;
	align-items: center;
	text-align: center;
}
